import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import RequestedPlants from "../RequestedPlants/RequestedPlants";
import "./style.css";
import CustomModal from "../Modal/Modal";
import EmployeeAttachment from "../EmployeeAttachment/EmployeeAttachment";
import CustomerAttachment from "../CustomerAttachment/CustomerAttachment";
import RequestTabForm from "../RequestTabForm/RequestTabForm";
import { Loading } from "../../pages/Loading";
import arrow from "../../assets/icons/arrow.png";
import close from "../../assets/icons/close.png";
import TabComponent from "../Tab/Tab";
import { Button } from "../Button";
import EmployeeNote from "../EmployeeNote/EmployeeNote";
import DragFiles from "../DragFiles/DragFiles";
import { Select, DatePicker, Avatar, TimePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import CustomTable from "../Table/Table";
import Swal from "sweetalert2";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../context/LanguageContext";

const RightSideBar = ({
  isSkelton,
  setLeftWidth,
  leftWidth,
  selectedPage,
  isAdmin,
  data,
  employees,
  setSelectedEmployee,
  handleAssignRequest,
  handleAppointmentDate,
  RefetchRequestList,
  handleAppointment,
  AppointmentDate,
  setRefetchData,
  SelectedEmployee,
  setUpdatedData,
  setHeadphoneRefetch,
  setListStatus,
  setSelectedRowKey
}) => {
  // console.log('data: ', data);
  const employeeAssigned =
    data?.fullData?.employee_id && data?.fullData?.employee_id !== "0"
      ? data?.fullData?.employee_id
      : "";
  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");
  const intl = useIntl();
 

  // const checkApprovalStatus = (dataArray) => {
  //   const userDesignationIds = user.positions.map((pos) => pos.designation_id);
  //   for (let i = 0; i < dataArray?.length; i++) {
  //     const currentObject = dataArray[i];

  //     // Check if the current object's status is "PENDING"
  //     if (currentObject.approval_status === "PENDING") {
  //       // Check if the position_id matches user.designation_id
  //       // if (currentObject.position_id === user?.designation_id) {
  //       //   return true; // Matching position ID found with "PENDING" status
  //       // } else {
  //       //   return false; // Stop further checking if no match
  //       // }
  //       if (userDesignationIds.includes(currentObject.position_id)) {
  //         // return true; // Matching position ID found with "PENDING" status
  //         if (currentObject.approval_status === "PENDING") {
  //           return true;
  //         }

  //         // If status is "APPROVED", return false immediately
  //         if (currentObject.approval_status === "APPROVED") {
  //           return false;
  //         }
  //       } else {
  //         return false; // Stop further checking if no match
  //       }
  //     }
  //     // If status is "APPROVED", continue to next item
  //     else if (currentObject.approval_status !== "APPROVED") {
  //       break; // Stop if current status is not "APPROVED"
  //     }
  //   }
  //   return false; // No match found in sequence
  // };
  // const isMatch = checkApprovalStatus(data?.fullData?.approvals);
  // console.log("isMatch: ", isMatch);


  const checkApprovalStatus = (dataArray) => {
    const userDesignationIds = user.positions.map((pos) => pos.designation_id);
  
    for (let i = 0; i < dataArray?.length; i++) {
      const currentObject = dataArray[i];
  
      // Check if the current object's status is "PENDING"
      if (currentObject.approval_status === "PENDING") {
        if (userDesignationIds.includes(currentObject.position_id)) {
          // Matching position ID found with "PENDING" status
          if (currentObject.approval_status === "PENDING") {
            return { index: i, result: true };
          }
  
          // If status is "APPROVED", return false immediately
          if (currentObject.approval_status === "APPROVED") {
            return { index: i, result: false };
          }
        } else {
          return { index: i, result: false }; // Stop further checking if no match
        }
      }
      // If status is "APPROVED", continue to next item
      else if (currentObject.approval_status !== "APPROVED") {
        break; // Stop if current status is not "APPROVED"
      }
    }
    return { index: -1, result: false }; // No match found in sequence
  };
  
  const isMatch = checkApprovalStatus(data?.fullData?.approvals);
  
  

  const { locale, setLocale } = useContext(LanguageContext);

  const { Option } = Select;
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("request_details");
  const [selectedTab2, setSelectedTab2] = useState("progress_log");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [EmployeesNote, setEmployeesNote] = useState();
  const [CustomersNote, setCustomersNote] = useState();
  const [extractedPlants, setExtractedPlants] = useState([]);

  // const openModal = () => {
  //   setModalOpen(true);
  // };

  useEffect(() => {
    setEmployeesNote(data?.fullData?.employee_notes);
  }, [data]);

  const closeModal = () => {
    setModalOpen(false);
  };
  const handleTabSelect = (selectedKey) => {
    setSelectedTab(selectedKey);
  };
  const handleTabSelect2 = (selectedKey) => {
    setSelectedTab2(selectedKey);
  };
  const handleEmployeeSelect = (value) => {
    setSelectedEmployee(value);
  };

  // const handleTimeChange = (time, timeString) => {
  //   console.log(time, timeString);
  // };

  // const disablePastDates = (current) => {
  //   return current && current <= moment().endOf('day');
  // };
  const disablePastDates = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of today
    const tomorrow = new Date(today);
    // tomorrow.setDate(tomorrow.getDate() + 1); // Set to tomorrow

    // Convert current date to start of day for comparison
    const currentDate = new Date(current);
    currentDate.setHours(0, 0, 0, 0);

    return currentDate < tomorrow;
  };

  const initialDate = data?.fullData?.appointment_date
    ? dayjs(data.fullData.appointment_date)
    : null;

  // const TableData = [data?.fullData];
  const TableAssignedData = data?.fullData?.request_logs?.filter(
    (log) => log.action_on === "AssignToEmployee"
  );
  const TableAppointmentData = data?.fullData?.request_logs?.filter(
    (log) => log.action_on === "UpdateAppointment"
  );
  const TableNotesData = data?.fullData?.request_logs?.filter(
    (log) => log.action_on === "EMP_NOTES"
  );

  const columns = [
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
    {
      title: <FormattedMessage id="ID" />,
      dataIndex: "employee_id",
      width: 4,
      render: (text, record) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="Employee_name" />,
      dataIndex: "fullname",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <Avatar shape="square" size="large" />
          <span style={{ marginRight: "10px", marginLeft: "10px" }}>
            {record?.employee?.fullname}
          </span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Date_&_Time" />,
      dataIndex: "created",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
  ];
  const AssignedColumns = [
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
    {
      title: <FormattedMessage id="ID" />,
      dataIndex: "employee_id",
      width: 4,
      render: (text, record) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="Employee_name" />,
      dataIndex: "fullname",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <Avatar shape="square" size="large" />
          <span style={{ marginRight: "10px", marginLeft: "10px" }}>
            {record?.employee?.fullname}
          </span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Date_&_Time" />,
      dataIndex: "created",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
  ];
  const columnsNotes = [
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
    {
      title: <FormattedMessage id="ID" />,
      dataIndex: "employee_id",
      width: 4,
      render: (text, record) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="Employee_name" />,
      dataIndex: "fullname",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <Avatar shape="square" size="large" />
          <span style={{ marginRight: "10px", marginLeft: "10px" }}>
            {record?.employee?.fullname}
          </span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Notes" />,
      dataIndex: "employee_notes",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Date_&_Time" />,
      dataIndex: "created",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
  ];
  const columnsOfficeNotes = [
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
    {
      title: <FormattedMessage id="ID" />,
      dataIndex: "id",
      width: 4,
      render: (text, record) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="Employee_name" />,
      dataIndex: "employee",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <Avatar shape="square" size="large" />
          <span style={{ marginRight: "10px", marginLeft: "10px" }}>
            {record?.employee?.fullname}
          </span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Notes" />,
      dataIndex: "employee_notes",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Date_&_Time" />,
      dataIndex: "created",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
  ];
  const columnsCustomerNotes = [
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
    {
      title: <FormattedMessage id="ID" />,
      dataIndex: "id",
      width: 4,
      render: (text, record) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="Employee_name" />,
      dataIndex: "employee",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <Avatar shape="square" size="large" />
          <span style={{ marginRight: "10px", marginLeft: "10px" }}>
            {record?.employee?.fullname}
          </span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Notes" />,
      dataIndex: "employee_notes",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Date_&_Time" />,
      dataIndex: "created",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
  ];
  const columnsApprovelLogs = [
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   width: 4,
    //   render: (text, record) => (
    //     <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
    //       {record?.EmployeeAppointment?.id}
    //     </span>
    //   ),
    // },
    {
      title: <FormattedMessage id="Employee_name" />,
      width: 100,
      dataIndex: "approval_status",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          {/* <Avatar shape="square" size="large" style={{ marginRight: "10px" }} /> */}
          <span>{record?.employee?.fullname}</span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Status" />,
      width: 100,
      dataIndex: "approval_status",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          {/* <Avatar shape="square" size="large" style={{ marginRight: "10px" }} /> */}
          <span>{text}</span>
        </div>
      ),
    },
    // {
    //   title: <FormattedMessage id="Employee_notes" />,
    //   width: 100,
    //   dataIndex: "employee_notes",
    //   render: (text, record) => (
    //     <div
    //       className="avatar-name-container"
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         wordWrap: "break-word",
    //       }}
    //     >
    //       {/* <Avatar shape="square" size="large" style={{ marginRight: "10px" }} /> */}
    //       <span>{text}</span>
    //     </div>
    //   ),
    // },
    {
      title: <FormattedMessage id="Date_&_Time" />,
      width: 500,
      dataIndex: "modified",
      render: (text, record) => (
        <div className="avatar-name-container">
          {/* <Avatar shape="square" size="large" style={{ marginRight: "10px" }} /> */}
          <span>{text}</span>
        </div>
      ),
    },

    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
  ];

  const tabs = [
    {
      event_key: "request_details",
      title: <FormattedMessage id="Request_Details" />,
      component:
        selectedPage == "headphone" ? (
          <RequestTabForm
            data={data}
            request={<FormattedMessage id="Request_No" />}
            DateTime={<FormattedMessage id="Request_Date_&_Time" />}
            message={<FormattedMessage id="Message" />}
            // service="Service name"
            // requestValue="Requests"
          />
        ) : (
          <RequestTabForm
            data={data}
            request={<FormattedMessage id="Request_No" />}
            requestDate={<FormattedMessage id="Request_Date_&_Time" />}
            service={<FormattedMessage id="Service_name" />}
            requestValue={<FormattedMessage id="Requests" />}
          />
        ),
    },
    {
      event_key: "requester_details",
      title: <FormattedMessage id="Requester_Details" />,
      component:
        selectedPage == "headphone" ? (
          <RequestTabForm
            data={data}
            customerName={<FormattedMessage id="Customer_Name" />}
            customerEmirateID={<FormattedMessage id="Requester_Emirates_ID" />}
            customerMobileNo={<FormattedMessage id="Mobile_number" />}
            customerEmail={<FormattedMessage id="Email_address" />}
          />
        ) : (
          <RequestTabForm
            data={data}
            requesterName={<FormattedMessage id="Requester_Name" />}
            emirateID={<FormattedMessage id="Requester_Emirates_ID" />}
            mobileNo={<FormattedMessage id="Mobile_number" />}
            email={<FormattedMessage id="Email_address" />}
            address={<FormattedMessage id="Address" />}
          />
        ),
    },
    {
      event_key: "attachment_details",
      title: <FormattedMessage id="Request_Attachments" />,
      component: (
        <div style={{ background: "#DAF4E1", padding: "10px" }}>
          <CustomerAttachment
            title={<FormattedMessage id="Request_Attachments" />}
            data={data}
          />
        </div>
      ),
    },
  ];

  const tabs2 = [
    {
      event_key: "progress_log",
      title: <FormattedMessage id="Progress_Log" />,
      component: (
        // <RequestTabForm
        //   data={data}
        //   request="Request No"
        //   requestDate="Request Date"
        //   service="Service name"
        // />
        <div>
          <CustomTable
            pagination={false}
            data={data?.fullData?.approvals}
            columns={columnsApprovelLogs}
          />
        </div>
      ),
    },
    {
      event_key: "progress_flow",
      title: <FormattedMessage id="Progress_Flow" />,
      component:
        selectedPage == "headphone" ? (
          <RequestTabForm
            data={data}
            customerName="Requester Name"
            customerEmirateID="Requester Emirates ID"
            customerMobileNo="Mobile number"
            customerEmail="Email address"
          />
        ) : (
          <RequestTabForm
            data={data}
            requesterName={<FormattedMessage id="Requester_Name" />}
            emirateID={<FormattedMessage id="Requester_Emirates_ID" />}
            mobileNo={<FormattedMessage id="Mobile_number" />}
            email={<FormattedMessage id="Email_address" />}
            address={<FormattedMessage id="Address" />}
          />
          // <RequestTabForm
          //   data={data}
          //   requesterName="Requester Name"
          //   emirateID="Requester Emirates ID"
          //   mobileNo="Mobile number"
          //   email="Email address"
          //   address="Address"
          // />
        ),
    },
  ];

  const width = parseInt(leftWidth?.split("%")[0]);
  const rightWidth = 100 - width;
  const newWidth = 392 + rightWidth;

  const handleEmployeeNotes = async () => {
    if (data && EmployeesNote) {
      setIsLoading(true);
      const body = {
        employee_id: user?.id,
        request_id: data?.number,
        department_id: user?.department_id,
        employee_notes: EmployeesNote,
      };
      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_URL}/ServiceRequests/saveNotes`,
          body
        )
        .then((res) => {
          // console.log(res?.data);
          setIsLoading(false);
          RefetchRequestList();
          RefetchRequestList();
          setIsLoading(false);
          setUpdatedData("");
          setRefetchData((prev) => !prev);
          const SelectedRow = JSON.parse(localStorage.getItem("selectedRowKey"))
          setSelectedRowKey(SelectedRow)
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "Notes Saved",
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  };
  const handleCustomerNotes = async () => {
    if (data && CustomersNote) {
      setIsLoading(true);
      const body = {
        employee_id: user?.id,
        request_id: data?.number,
        department_id: user?.department_id,
        employee_notes: CustomersNote,
      };
      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_URL}/SuggestionComplaints/saveCustomerNotes`,
          body
        )
        .then((res) => {
          // console.log(res?.data);
          setIsLoading(false);
          setUpdatedData("");
          setHeadphoneRefetch((prev) => !prev);
          const SelectedCard = sessionStorage.getItem("SelectedCard");
          setListStatus(SelectedCard);
          const SelectedRow = JSON.parse(localStorage.getItem("selectedRowKey"))
          setSelectedRowKey(SelectedRow)
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "Notes Saved",
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  };
  const handleOfficeNotes = async () => {
    if (data && EmployeesNote) {
      setIsLoading(true);
      const body = {
        employee_id: user?.id,
        request_id: data?.number,
        department_id: user?.department_id,
        employee_notes: EmployeesNote,
      };
      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_URL}/SuggestionComplaints/saveOfficeNotes`,
          body
        )
        .then((res) => {
          // console.log(res?.data);
          setHeadphoneRefetch((prev) => !prev);
          const SelectedCard = sessionStorage.getItem("SelectedCard");
          setListStatus(SelectedCard);
          setIsLoading(false);
          setUpdatedData("");
          const SelectedRow = JSON.parse(localStorage.getItem("selectedRowKey"))
          setSelectedRowKey(SelectedRow)
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "Notes Saved",
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  };

  // const handleRequestReject = () => {
  //   if (data) {
  //     Swal.fire({
  //       title: "Are you sure you want to reject this?",
  //       showDenyButton: true,
  //       showCancelButton: false,
  //       confirmButtonText: "Yes",
  //       denyButtonText: "No",
  //       customClass: {
  //         actions: "my-actions",
  //         cancelButton: "order-1 right-gap",
  //         confirmButton: "order-2",
  //         denyButton: "order-3",
  //       },
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         setIsLoading(true); // Start loading after confirmation
  //         const formData = new FormData();
  //         formData.append("request_id", data?.number);
  //         formData.append("employee_id", user?.id);
  //         formData.append("status", "REJECTED");
  //         formData.append("employee_notes", EmployeesNote);

  //         // If you want to handle file attachments in the future
  //         // selectedFile?.length > 0 && formData.append("filename", selectedFile[0]?.name);
  //         // selectedFile?.length > 0 && formData.append("filetype", "pdf");
  //         // selectedFile?.length > 0 && formData.append("employee_attachment", selectedFile[0]);
  //         // formData.append("delivery_date", "22-33-2222");

  //         // Make the API call after confirmation
  //         axios
  //           .post(
  //             `${process.env.REACT_APP_PUBLIC_URL}/ServiceRequests/reject`,
  //             formData
  //           )
  //           .then((res) => {
  //             setIsLoading(false); // Stop loading after successful API call
  //             const SelectedCard = sessionStorage.getItem("SelectedCard");
  //             setListStatus(SelectedCard);
  //             setHeadphoneRefetch((prev) => !prev); // Refresh the data

  //             // Show success toast
  //             const Toast = Swal.mixin({
  //               toast: true,
  //               position: "top-end",
  //               showConfirmButton: false,
  //               timer: 3000,
  //               timerProgressBar: true,
  //               didOpen: (toast) => {
  //                 toast.onmouseenter = Swal.stopTimer;
  //                 toast.onmouseleave = Swal.resumeTimer;
  //               },
  //             });
  //             Toast.fire({
  //               title: res?.data?.msg, // Display success message
  //             });
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //             setIsLoading(false); // Stop loading in case of error

  //             // Show error toast
  //             const Toast = Swal.mixin({
  //               toast: true,
  //               position: "top-end",
  //               showConfirmButton: false,
  //               timer: 3000,
  //               timerProgressBar: true,
  //               didOpen: (toast) => {
  //                 toast.onmouseenter = Swal.stopTimer;
  //                 toast.onmouseleave = Swal.resumeTimer;
  //               },
  //             });
  //             Toast.fire({
  //               icon: "error",
  //               title: "Something went wrong",
  //             });
  //           });
  //       } else if (result.isDenied) {
  //         // Optional: handle denial logic if needed
  //       }
  //     });
  //   }
  // };

  const handleRequestReject = () => {
    if (data) {
      Swal.fire({
        title: intl.formatMessage({ id: "reject_message" }),
        input: "text",
        inputPlaceholder: intl.formatMessage({ id: "reject_reason" }),
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: intl.formatMessage({ id: "yes" }),
        denyButtonText: intl.formatMessage({ id: "no" }),
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
        preConfirm: (rejectReason) => {
          if (!rejectReason) {
            Swal.showValidationMessage(<FormattedMessage id="reject_reason" />);
          }
          return rejectReason;
        },
      }).then((result) => {
        if (result.isConfirmed && result.value) {
          setIsLoading(true); // Start loading after confirmation
          const formData = new FormData();
          formData.append("request_id", data?.number);
          formData.append("employee_id", user?.id);
          formData.append("status", "REJECTED");
          formData.append("reject_reason", result.value); // Use input value as reason

          axios
            .post(
              `${process.env.REACT_APP_PUBLIC_URL}/ServiceRequests/reject`,
              formData
            )
            .then((res) => {
              setIsLoading(false); // Stop loading after successful API call
              const SelectedCard = sessionStorage.getItem("SelectedCard");
              setListStatus(SelectedCard);
              setHeadphoneRefetch((prev) => !prev); // Refresh the data
              localStorage.removeItem("selectedRowKey")
              setSelectedRowKey(null)

              // Show success toast
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                },
              });
              Toast.fire({
                title: res?.data?.msg, // Display success message
              });
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false); // Stop loading in case of error

              // Show error toast
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                },
              });
              Toast.fire({
                icon: "error",
                title: "Something went wrong",
              });
            });
        } else if (result.isDenied) {
          // Optional: handle denial logic if needed
        }
      });
    }
  };

  // const handlePageHeadphoneReject = () => {
  //   if (data && user && token) {
  //     Swal.fire({
  //       title: "Are you sure you want to reject this?",
  //       showDenyButton: true,
  //       showCancelButton: false,
  //       confirmButtonText: "Yes",
  //       denyButtonText: "No",
  //       customClass: {
  //         actions: "my-actions",
  //         cancelButton: "order-1 right-gap",
  //         confirmButton: "order-2",
  //         denyButton: "order-3",
  //       },
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         setIsLoading(true); // Set loading before hitting API
  //         const body = {
  //           employee_id: user?.id,
  //           request_id: data?.number,
  //           department_id: user?.department_id,
  //           status: "REJECT", // or "APPROVED" depending on your logic
  //           token: token,
  //         };

  //         // Now make the API call
  //         axios
  //           .post(
  //             `${process.env.REACT_APP_PUBLIC_URL}/SuggestionComplaints/updateRequestStatus`,
  //             body
  //           )
  //           .then((res) => {
  //             setIsLoading(false); // Turn off loading
  //             setHeadphoneRefetch((prev) => !prev);
  //             const SelectedCard = sessionStorage.getItem("SelectedCard");
  //             setListStatus(SelectedCard);

  //             // Show success toast
  //             const Toast = Swal.mixin({
  //               toast: true,
  //               position: "top-end",
  //               showConfirmButton: false,
  //               timer: 3000,
  //               timerProgressBar: true,
  //               didOpen: (toast) => {
  //                 toast.onmouseenter = Swal.stopTimer;
  //                 toast.onmouseleave = Swal.resumeTimer;
  //               },
  //             });
  //             Toast.fire({
  //               title: res?.data?.msg,
  //             });
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //             setIsLoading(false);

  //             // Show error toast
  //             const Toast = Swal.mixin({
  //               toast: true,
  //               position: "top-end",
  //               showConfirmButton: false,
  //               timer: 3000,
  //               timerProgressBar: true,
  //               didOpen: (toast) => {
  //                 toast.onmouseenter = Swal.stopTimer;
  //                 toast.onmouseleave = Swal.resumeTimer;
  //               },
  //             });
  //             Toast.fire({
  //               icon: "error",
  //               title: "Something went wrong",
  //             });
  //           });
  //       } else if (result.isDenied) {
  //         // Handle 'No' action if needed
  //       }
  //     });
  //   }
  // };

  const handlePageHeadphoneReject = () => {
    if (data && user && token) {
      Swal.fire({
        title: intl.formatMessage({ id: "reject_message" }),
        input: "text",
        inputPlaceholder: intl.formatMessage({ id: "reject_reason" }),
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: intl.formatMessage({ id: "yes" }),
        denyButtonText: intl.formatMessage({ id: "no" }),
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
        preConfirm: (rejectReason) => {
          if (!rejectReason) {
            Swal.showValidationMessage(<FormattedMessage id="reject_reason" />);
          }
          return rejectReason;
        },
      }).then((result) => {
        if (result.isConfirmed && result.value) {
          setIsLoading(true); // Set loading before hitting API
          const body = {
            employee_id: user?.id,
            request_id: data?.number,
            department_id: user?.department_id,
            status: "REJECT",
            token: token,
            reject_reason: result.value, // Use input value as reason
          };

          // Make the API call
          axios
            .post(
              `${process.env.REACT_APP_PUBLIC_URL}/SuggestionComplaints/updateRequestStatus`,
              body
            )
            .then((res) => {
              setIsLoading(false); // Turn off loading
              setHeadphoneRefetch((prev) => !prev);
              const SelectedCard = sessionStorage.getItem("SelectedCard");
              setListStatus(SelectedCard);
              localStorage.removeItem("selectedRowKey")
              setSelectedRowKey(null)

              // Show success toast
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                },
              });
              Toast.fire({
                title: res?.data?.msg,
              });
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);

              // Show error toast
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                },
              });
              Toast.fire({
                icon: "error",
                title: "Something went wrong",
              });
            });
        } else if (result.isDenied) {
          // Handle 'No' action if needed
        }
      });
    }
  };

  const handleEmployeeAttachmentDelete = (id) => {
    if (id) {
      // setIsLoading(true);
      const formData = new FormData();
      formData.append("request_id", data?.number);
      formData.append("employee_id", user?.id);
      formData.append("token", token);
      formData.append("fileid", id);

      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_URL}/ServiceRequests/deleteRquestAttachments`,
          formData
        )
        .then((res) => {
          // console.log(res?.data);
          Swal.fire({
            title: intl.formatMessage({ id: "are_you_sure_delete_file" }),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: intl.formatMessage({ id: "yes" }),
            denyButtonText: intl.formatMessage({ id: "no" }),
            customClass: {
              actions: "my-actions",
              cancelButton: "order-1 right-gap",
              confirmButton: "order-2",
              denyButton: "order-3",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              // console.log(res?.data);
              RefetchRequestList();
              setIsLoading(false);
              setUpdatedData("");
              // alert(res?.data?.msg);
              setRefetchData((prev) => !prev);
              const SelectedCard = sessionStorage.getItem("SelectedCard");
              setListStatus(SelectedCard);
              const SelectedRow = JSON.parse(localStorage.getItem("selectedRowKey"))
              setSelectedRowKey(SelectedRow)
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                },
              });
              Toast.fire({
                // icon: "success",
                title: res?.data?.msg,
              });
            } else if (result.isDenied) {
              // Swal.fire("Changes are not saved", "", "info");
            }
          });
        })
        // .then((res) => {

        //   const Toast = Swal.mixin({
        //     toast: true,
        //     position: "top-end",
        //     showConfirmButton: false,
        //     timer: 3000,
        //     timerProgressBar: true,
        //     didOpen: (toast) => {
        //       toast.onmouseenter = Swal.stopTimer;
        //       toast.onmouseleave = Swal.resumeTimer;
        //     },
        //   });
        // })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  };

  useEffect(() => {
    if (selectedFile?.length > 0 && selectedPage !== "headphone") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("request_id", data?.number);
      formData.append("employee_id", user?.id);
      formData.append("token", token);
      formData.append("attachment", selectedFile[0]);

      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_URL}/ServiceRequests/uploadRquestAttachments`,
          formData
        )
        .then((res) => {
          // console.log(res?.data);
          RefetchRequestList();
          setIsLoading(false);
          setUpdatedData("");
          // alert(res?.data?.msg);
          setRefetchData((prev) => !prev);
          const SelectedCard = sessionStorage.getItem("SelectedCard");
          setListStatus(SelectedCard);
          const SelectedRow = JSON.parse(localStorage.getItem("selectedRowKey"))
          setSelectedRowKey(SelectedRow)
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
    if (selectedFile?.length > 0 && selectedPage == "headphone") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("request_id", data?.number);
      formData.append("employee_id", user?.id);
      formData.append("token", token);
      formData.append("attachment", selectedFile[0]);

      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_URL}/SuggestionComplaints/uploadRquestAttachments`,
          formData
        )
        .then((res) => {
          // console.log(res?.data);
          setIsLoading(false);
          setUpdatedData("");
          // alert(res?.data?.msg);
          setHeadphoneRefetch((prev) => !prev);
          const SelectedCard = sessionStorage.getItem("SelectedCard");
          setListStatus(SelectedCard);
          const SelectedRow = JSON.parse(localStorage.getItem("selectedRowKey"))
          setSelectedRowKey(SelectedRow)
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  }, [selectedFile]);

  return (
    <>
      <Col
        className={"right-side-bar-container"}
        style={{ height: width < 70 ? "" : "100vh", paddingBottom: "20px" }}
      >
        {selectedPage == "requests"
          ? !isAdmin && (
              <Row>
                <Button
                  className={width < 70 ? "reply-button" : "reply-button-small"}
                  title={width < 70 ? "Reply" : <img src={arrow} />}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                />
              </Row>
            )
          : !isAdmin && (
              <Row
                style={{
                  display: width < 70 ? "" : "flex",
                  flexDirection: width < 70 ? "row" : "column",
                  gap: width < 70 ? "" : "16px",
                  position: "fixed",
                  top: 0,
                  zIndex: 100,
                  marginTop: "20px",
                }}
              >
                {/* {user &&
                  (user.designation_id === "2" ||
                    user.designation_id === "1") &&
                  data?.status === "IN_PROGRES" && (
                    <>
                      <Col lg={6}>
                        {isSkelton ? (
                          <SkeletonTheme
                            baseColor="#CDCDCD"
                            height={52}
                            width={188}
                          >
                            <div>
                              <Skeleton />
                            </div>
                          </SkeletonTheme>
                        ) : (
                          <Button
                            className={
                              width < 70 ? "send-button" : "send-button-small"
                            }
                            title={width < 70 ? "Approve" : <img src={arrow} />}
                            onClick={() => {
                              setModalOpen(true);
                            }}
                          />
                        )}
                      </Col>
                      <Col lg={6}>
                        {isSkelton ? (
                          <SkeletonTheme
                            baseColor="#CDCDCD"
                            height={52}
                            width={188}
                          >
                            <div>
                              <Skeleton />
                            </div>
                          </SkeletonTheme>
                        ) : (
                          <Button
                            className={
                              width < 70
                                ? "reject-button"
                                : "reject-button-small"
                            }
                            title={width < 70 ? "Reject" : <img src={close} />}
                            onClick={handleRequestReject}
                          />
                        )}
                      </Col>
                    </>
                  )} */}
                {(data?.status === "IN_PROGRES" &&
                  selectedPage !== "headphone" &&
                  (user?.designation_id === "1" ||
                    user?.designation_id === "2")) ||
                (data?.status === "PENDING" &&
                  selectedPage !== "headphone" &&
                  (user?.designation_id === "1" ||
                    user?.designation_id === "2")) ||
                (data?.fullData?.employee_id &&
                  data?.fullData?.employee_id !== "0" &&
                  data?.fullData?.employee_id === user?.id && isMatch.result) ||
                (data?.status === "PENDING" &&
                  isMatch.result &&
                  selectedPage !== "headphone") ? (
                  // && data?.fullData?.employee_id &&
                  // data?.fullData?.employee_id !== "0" &&
                  // data?.fullData?.employee_id === user?.id
                  <>
                    <Col lg={6}>
                      {isSkelton ? (
                        <SkeletonTheme
                          baseColor="#CDCDCD"
                          height={52}
                          width={188}
                        >
                          <div>
                            <Skeleton />
                          </div>
                        </SkeletonTheme>
                      ) : (
                        <Button
                          className={
                            width < 70 ? "send-button" : "send-button-small"
                          }
                          title={
                            width < 70 ? (
                              <FormattedMessage id="Approve" />
                            ) : (
                              <img src={arrow} />
                            )
                          }
                          onClick={() => {
                            setModalOpen(true);
                          }}
                        />
                      )}
                    </Col>
                    <Col lg={6}>
                      {isSkelton ? (
                        <SkeletonTheme
                          baseColor="#CDCDCD"
                          height={52}
                          width={188}
                        >
                          <div>
                            <Skeleton />
                          </div>
                        </SkeletonTheme>
                      ) : (
                        <Button
                          className={
                            width < 70 ? "reject-button" : "reject-button-small"
                          }
                          title={
                            width < 70 ? (
                              <FormattedMessage id="Reject" />
                            ) : (
                              <img src={close} />
                            )
                          }
                          onClick={handleRequestReject}
                        />
                      )}
                    </Col>
                  </>
                ) : null}
                {selectedPage === "headphone" &&
                  data?.status === "PENDING" &&
                  data &&
                  Object.keys(data).length > 0 && (
                    <>
                      <Col lg={6}>
                        {isSkelton ? (
                          <SkeletonTheme
                            baseColor="#CDCDCD"
                            height={52}
                            width={188}
                          >
                            <div>
                              <Skeleton />
                            </div>
                          </SkeletonTheme>
                        ) : (
                          <Button
                            className={
                              width < 70 ? "send-button" : "send-button-small"
                            }
                            disable={
                              selectedPage == "headphone" &&
                              user?.canSupport?.permissions === "RO"
                            }
                            style={{
                              cursor: `${
                                selectedPage === "headphone" &&
                                user?.canSupport?.permissions === "RO"
                                  ? "not-allowed"
                                  : "pointer"
                              }`,
                            }}
                            title={
                              width < 70 ? (
                                <FormattedMessage id="Approve" />
                              ) : (
                                <img src={arrow} />
                              )
                            }
                            onClick={() => {
                              setModalOpen(true);
                            }}
                          />
                        )}
                      </Col>
                      <Col lg={6}>
                        {isSkelton ? (
                          <SkeletonTheme
                            baseColor="#CDCDCD"
                            height={52}
                            width={188}
                          >
                            <div>
                              <Skeleton />
                            </div>
                          </SkeletonTheme>
                        ) : (
                          <Button
                            className={
                              width < 70
                                ? "reject-button"
                                : "reject-button-small"
                            }
                            disable={
                              selectedPage == "headphone" &&
                              user?.canSupport?.permissions === "RO"
                            }
                            style={{
                              cursor: `${
                                selectedPage === "headphone" &&
                                user?.canSupport?.permissions === "RO"
                                  ? "not-allowed"
                                  : "pointer"
                              }`,
                            }}
                            title={
                              width < 70 ? (
                                <FormattedMessage id="Reject" />
                              ) : (
                                <img src={close} />
                              )
                            }
                            onClick={handlePageHeadphoneReject}
                          />
                        )}
                      </Col>
                    </>
                  )}
              </Row>
            )}

        {/* Tabs Section Start */}
        <Row style={{ marginTop: "100px" }}>
          <Col sm={12}>
            {isSkelton ? (
              <SkeletonTheme baseColor="#CDCDCD" width={392} height={301}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            ) : width < 70 ? (
              !isAdmin && (
                <div
                  style={{
                    height: "301px",
                    width: width >= 65 ? "392px" : `100%`,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    paddingLeft: width >= 65 ? "0px" : "50px",
                    marginRight: width >= 65 ? "0px" : "20px",
                  }}
                >
                  <TabComponent
                    selectedTab={selectedTab}
                    handleTabSelect={handleTabSelect}
                    tabs={tabs}
                  />
                </div>
              )
            ) : (
              ""
            )}
          </Col>
        </Row>
        {/* Tabs Section End */}
        <>
          {
            // (
            // (data?.status === "IN_PROGRES" &&
            // selectedPage !== "headphone" &&
            // (user?.designation_id === "1" || user?.designation_id === "2")) ||
            // (data?.status === "PENDING" && selectedPage !== "headphone")) && (
            data?.status === "PENDING" && selectedPage !== "headphone" && (
              <>
                {!isAdmin && (
                  <Row style={{ width: "85%" }}>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          marginLeft: 0,
                          direction: locale === "en" ? "ltr" : "rtl",
                        }}
                      >
                        <p className="requestPlants">
                          {" "}
                          <FormattedMessage id="Assign_Request" />
                        </p>
                        {data?.fullData?.employee_id &&
                          data?.fullData?.employee_id !== "0" &&
                          data?.fullData?.employee_id === user?.id && (
                            <button
                              className="assign-button"
                              style={{ backgroundColor: "red" }}
                              onClick={() => {
                                const employeeId =
                                  data?.fullData?.employee_id &&
                                  data?.fullData?.employee_id !== "0"
                                    ? data.fullData.employee_id
                                    : "";

                                handleEmployeeSelect(employeeId);
                                handleAssignRequest();
                              }}
                            >
                              <FormattedMessage id="unAssign" />
                            </button>
                          )}
                        {data?.fullData?.employee_id &&
                          data?.fullData?.employee_id === "0" &&
                          isMatch.result && (
                            <button
                              className="assign-button"
                              onClick={handleAssignRequest}
                            >
                              <FormattedMessage id="Assign" />
                            </button>
                          )}
                      </div>
                      <Select
                        style={{
                          width: "100%",
                          height: "42px",
                          background: "#F6F6F6",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "normal",
                          border: "none",
                        }}
                        value={employeeAssigned || SelectedEmployee}
                        placeholder="Assign Request"
                        onChange={handleEmployeeSelect}
                      >
                        {user &&
                        (user.designation_id === "2" ||
                          user.designation_id === "1") ? (
                          <>
                            {employees?.data?.map(
                              (employee, i) =>
                                employee?.employee?.fullname && (
                                  <Option
                                    style={{ fontWeight: 600 }}
                                    key={i}
                                    value={employee?.employee?.id}
                                  >
                                    {employee?.employee?.fullname}
                                  </Option>
                                )
                            )}
                          </>
                        ) : (
                          user && (
                            <Option
                              style={{ fontWeight: 600 }}
                              value={user?.id}
                            >
                              Assign to Myself
                            </Option>
                          )
                        )}
                      </Select>
                    </Col>
                  </Row>
                )}

                {data?.fullData && selectedPage !== "headphone" && (
                  <>
                    {data?.fullData?.request_logs?.some(
                      (log) => log.action_on === "AssignToEmployee"
                    ) && (
                      <div>
                        <p
                          className="requestPlants"
                          style={{
                            margin: "0 0 10px 0",
                            direction: locale === "en" ? "ltr" : "rtl",
                          }}
                        >
                          <FormattedMessage id="Assigning_Detail" />
                        </p>
                        <CustomTable
                          pagination={false}
                          data={TableAssignedData}
                          columns={AssignedColumns}
                          // direction={locale === "en" ? "ltr" : "rtl"}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )
          }

          {((data?.status === "IN_PROGRES" &&
            selectedPage !== "headphone" &&
            (user?.designation_id === "1" || user?.designation_id === "2")) ||
            (data?.status === "PENDING" && selectedPage !== "headphone")) && (
            <>
              {!isAdmin && (
                <Row style={{ width: "85%" }}>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "8px",
                        marginLeft: 0,
                        direction: locale === "en" ? "ltr" : "rtl",
                      }}
                    >
                      <p className="requestPlants">
                        {" "}
                        <FormattedMessage id="Appointment_Date_&_Time" />
                      </p>
                      <button
                        onClick={handleAppointment}
                        className="assign-button"
                      >
                        <FormattedMessage id="Save" />
                      </button>
                    </div>
                    <DatePicker
                      showTime={{
                        format: "HH:mm",
                        defaultValue: dayjs("00:00", "HH:mm"),
                      }}
                      value={
                        AppointmentDate ? dayjs(AppointmentDate) : initialDate
                      }
                      style={{
                        width: "100%",
                        height: "42px",
                        background: "#F6F6F6",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "normal",
                        border: "none",
                      }}
                      onChange={handleAppointmentDate}
                      disabledDate={disablePastDates}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>

        {data?.fullData &&
          selectedPage !== "headphone" &&
          data?.fullData?.request_logs?.some(
            (log) => log.action_on === "UpdateAppointment"
          ) && (
            <div>
              <p
                className="requestPlants"
                style={{
                  margin: "0 0 10px 0",
                  direction: locale === "en" ? "ltr" : "rtl",
                }}
              >
                <FormattedMessage id="Appointment_Details" />
              </p>
              <CustomTable
                pagination={false}
                data={TableAppointmentData}
                columns={columns}
                // direction={locale === "en" ? "ltr" : "rtl"}
              />
            </div>
          )}
        {/* Plants Section Start */}
        {!isAdmin && (
          <Row
            style={{ width: "100%", paddingLeft: isSkelton ? "30px" : "25px" }}
          >
            <Col>
              {isSkelton ? (
                <SkeletonTheme baseColor="#CDCDCD" height={138} width={392}>
                  <div>
                    <Skeleton />
                  </div>
                </SkeletonTheme>
              ) : width < 70 ? (
                // <div style={{ width: width >= 35 ? "auto" : `100%` }}>
                data?.fullData?.plants?.length > 0 && (
                  <RequestedPlants
                    width={width}
                    data={data}
                    setExtractedPlants={setExtractedPlants}
                    isMatch={isMatch}
                  />
                )
              ) : (
                // </div>
                ""
              )}
            </Col>
          </Row>
        )}
        {/* Plants Section End */}

        {/* Employee Notes Section Start */}
        <Row style={{ width: "85%" }}>
          {width < 70
            ? !isAdmin && (
                <>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "8px",
                      marginLeft: 0,
                      direction: locale === "en" ? "ltr" : "rtl",
                    }}
                  >
                    <p className="requestPlants">
                      <FormattedMessage id="Employee_notes" />
                    </p>
                    {((data?.status === "IN_PROGRES" &&
                      selectedPage !== "headphone" &&
                      (user?.designation_id === "1" ||
                        user?.designation_id === "2")) ||
                      (data?.status === "PENDING" &&
                        selectedPage !== "headphone")) && (
                      <button
                        onClick={handleEmployeeNotes}
                        className="assign-button"
                      >
                        <FormattedMessage id="Save" />
                      </button>
                    )}
                    {selectedPage == "headphone" &&
                      data?.status === "PENDING" && (
                        <button
                          onClick={handleOfficeNotes}
                          className="assign-button"
                          disabled={
                            selectedPage == "headphone" &&
                            user?.canSupport?.permissions === "RO"
                          }
                          style={{
                            cursor: `${
                              selectedPage === "headphone" &&
                              user?.canSupport?.permissions === "RO"
                                ? "not-allowed"
                                : ""
                            }`,
                          }}
                        >
                          <FormattedMessage id="Save" />
                        </button>
                      )}
                  </div>
                  <EmployeeNote
                    EmployeesNote={EmployeesNote}
                    setEmployeesNote={setEmployeesNote}
                    data={data}
                    disable={
                      selectedPage === "headphone" &&
                      user?.canSupport?.permissions === "RO"
                    }
                  />
                </>
              )
            : ""}
        </Row>

        {selectedPage == "headphone" &&
          data?.data &&
          data?.data?.officenotes?.length > 0 && (
            <CustomTable
              pagination={false}
              data={data?.data?.officenotes}
              columns={columnsOfficeNotes}
              // direction={locale === "en" ? "ltr" : "rtl"}
            />
          )}

        {/* Employee Notes Section Start */}
        {selectedPage == "headphone" && (
          <Row style={{ width: "85%" }}>
            {width < 70
              ? !isAdmin && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "8px",
                        marginLeft: 0,
                        direction: locale === "en" ? "ltr" : "rtl",
                      }}
                    >
                      <p className="requestPlants">
                        <FormattedMessage id="Customer_notes" />
                      </p>
                      {selectedPage == "headphone" &&
                        data?.status === "PENDING" && (
                          <button
                            onClick={handleCustomerNotes}
                            className="assign-button"
                            disabled={
                              selectedPage == "headphone" &&
                              user?.canSupport?.permissions === "RO"
                            }
                            style={{
                              cursor: `${
                                selectedPage === "headphone" &&
                                user?.canSupport?.permissions === "RO"
                                  ? "not-allowed"
                                  : "pointer"
                              }`,
                            }}
                          >
                            <FormattedMessage id="Save" />
                          </button>
                        )}
                    </div>
                    <EmployeeNote
                      EmployeesNote={CustomersNote}
                      setEmployeesNote={setCustomersNote}
                      data={data}
                      disable={
                        selectedPage === "headphone" &&
                        user?.canSupport?.permissions === "RO"
                      }
                    />
                  </>
                )
              : ""}
          </Row>
        )}
        {selectedPage == "headphone" &&
          data?.data &&
          data?.data?.customernotes?.length > 0 && (
            <CustomTable
              pagination={false}
              data={data?.data?.customernotes}
              columns={columnsCustomerNotes}
              // direction={locale === "en" ? "ltr" : "rtl"}
            />
          )}
        {/* Employee Notes Section End */}
        {data?.fullData &&
          data?.fullData?.request_logs?.some(
            (log) => log.action_on === "EMP_NOTES"
          ) && (
            <CustomTable
              pagination={false}
              data={TableNotesData}
              columns={columnsNotes}
              // direction={locale === "en" ? "ltr" : "rtl"}
            />
          )}

        {/* Employee Attachment Start */}
        {/* {data?.status !== "APPROVED" && data?.status !== "REJECTED" && data && (
        !isAdmin && (
          <Row
            style={{
              width: "100%",
              paddingLeft: isSkelton ? "30px" : "25px",
              paddingRight: "25px",
            }}
          >
            <Col>
              {isSkelton ? (
                <SkeletonTheme baseColor="#CDCDCD" height={301} width={392}>
                  <div>
                    <Skeleton />
                  </div>
                </SkeletonTheme>
              ) :
               width < 70 ? (
                <div style={{ width: "100%" }}>
                  <EmployeeAttachment
                    title={"Employee Attachment"}
                    selectedFile={selectedFile}
                    setselectedFile={setselectedFile}
                  />
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        ))}
      
        <Row>
          {width < 70
            ? !isAdmin && (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    marginTop: "20px",
                  }}
                >
                  <DragFiles setselectedFile={setselectedFile} />
                </div>
              )
            : ""}
        </Row>*/}
        {/* Uncomment and use Row and Col if needed for layout */}
        {/* !isAdmin && (
              <Row
                style={{
                  width: "100%",
                  paddingLeft: isSkeleton ? "30px" : "25px",
                  paddingRight: "25px",
                }}
              >
              <Col>
                  {isSkeleton ? (
                    <SkeletonTheme baseColor="#CDCDCD" height={301} width={392}>
                    <div>
                    <Skeleton />
                    </div>
                    </SkeletonTheme>
                    ) : */}
        {width < 70 ? (
          <div style={{ width: "85%" }}>
            <EmployeeAttachment
              title={<FormattedMessage id="Employee_Attachment" />}
              selectedPage={selectedPage}
              files={data}
              setSelectedFile={setSelectedFile}
              handleEmployeeAttachmentDelete={handleEmployeeAttachmentDelete}
            />
          </div>
        ) : (
          ""
        )}
        {/* Uncomment and use Row and Col if needed for layout */}
        {/* </Col>
              </Row> */}
        {((data?.status === "IN_PROGRES" &&
          selectedPage !== "headphone" &&
          (user?.designation_id === "1" || user?.designation_id === "2")) ||
          (data?.status === "PENDING" && selectedPage !== "headphone")) && (
          <Row>
            {width < 70 && !isAdmin && (
              <div
                style={{
                  width: "100%",
                  paddingLeft: "60px",
                  paddingRight: "60px",
                  marginTop: "20px",
                }}
              >
                <DragFiles setSelectedFile={setSelectedFile} />
              </div>
            )}
          </Row>
        )}

        {/* {selectedPage == "headphone" &&
          (width < 70 ? (
            <div style={{ width: "85%" }}>
              <EmployeeAttachment
                title={<FormattedMessage id="Employee_Attachment" />}
                selectedFile={selectedFile}
                files={data}
                setSelectedFile={setSelectedFile}
                selectedPage={selectedPage}
                handleEmployeeAttachmentDelete={handleEmployeeAttachmentDelete}
              />
            </div>
          ) : (
            ""
          ))} */}
        {/* Uncomment and use Row and Col if needed for layout */}
        {/* </Col>
              </Row> */}
        {selectedPage == "headphone" && (
          <Row>
            {width < 70 && !isAdmin && (
              <div
                style={{
                  width: "100%",
                  paddingLeft: "60px",
                  paddingRight: "60px",
                  marginTop: "20px",
                }}
              >
                <DragFiles
                  disable={
                    selectedPage === "headphone" &&
                    user?.canSupport?.permissions === "RO"
                  }
                  setSelectedFile={setSelectedFile}
                />
              </div>
            )}
          </Row>
        )}

        {selectedPage !== "headphone" && (
          <Row style={{ marginTop: "10px" }}>
            <Col sm={12}>
              {width < 70
                ? !isAdmin && (
                    <div
                      style={{
                        height: "301px",
                        width: width >= 65 ? "392px" : `100%`,
                        overflowY: "scroll",
                        overflowX: "hidden",
                        paddingLeft: width >= 65 ? "0px" : "50px",
                        marginRight: width >= 65 ? "0px" : "20px",
                      }}
                    >
                      <TabComponent
                        data={data}
                        selectedTab={selectedTab2}
                        handleTabSelect={handleTabSelect2}
                        tabs={tabs2}
                      />
                    </div>
                  )
                : ""}
            </Col>
          </Row>
        )}

        {/* Tabs Section End */}
      </Col>
      {isModalOpen && (
        <CustomModal
          data={data}
          isOpen={isModalOpen}
          onClose={closeModal}
          setIsLoading={setIsLoading}
          RefetchRequestList={RefetchRequestList}
          setRefetchData={setRefetchData}
          setHeadphoneRefetch={setHeadphoneRefetch}
          selectedFile={selectedFile}
          EmployeesNote={EmployeesNote}
          fetchRequestData={RefetchRequestList}
          extractedPlants={extractedPlants}
          setUpdatedData={setUpdatedData}
          selectedPage={selectedPage}
          setListStatus={setListStatus}
          setSelectedRowKey={setSelectedRowKey}
        />
      )}
      {isLoading && (
        <div
          style={{
            background: "rgb(99 99 99 / 9%);",
            // background: "red",
            position: "fixed",
            top: "0%",
            width: "100%",
            right: "0",
          }}
        >
          <Loading />
        </div>
      )}
    </>
  );
};

export default RightSideBar;

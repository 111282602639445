import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import minus from "../../assets/images/minus.png";
import plant from "../../assets/images/plant.png";
import plus from "../../assets/images/plus.png";
import "./style.css";
import { PiImageSquareBold } from "react-icons/pi";
import { LanguageContext } from "../../context/LanguageContext";

const RequestedPlantsCard = ({
  width,
  data,
  index,
  onQuantityChange,
  buttons,
  sidebarData,
  isMatch,
}) => {
  // console.log('isMatch: ', isMatch?.result);
  // console.log("buttons: ", buttons);
  // console.log("sidebarData", sidebarData);
  // console.log("data", data);
  const { locale, setLocale } = useContext(LanguageContext);
  const [quantity, setQuantity] = useState();
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    if (data && isMatch?.result && isMatch?.index === 0) {
      setQuantity(Number(data?.plant_qty) || 0);
    } else if (
      data &&
      (user?.designation_id === "1" || user?.designation_id === "2") && isMatch?.result === false
    ) {
      setQuantity(Number(data?.plant_qty) || 0);
    } else if (sidebarData) {
      setQuantity(Number(sidebarData?.plant_qty) || 0);
    } else {
      setQuantity(Number(data?.approved_qty));
    }
  }, [data, sidebarData]);

  useEffect(() => {
    onQuantityChange(index, quantity);
  }, [quantity]);

  const imageUrl = buttons ? data?.plant?.image : sidebarData?.plant?.image;
  const plantName = buttons
    ? locale === "ar"
      ? data?.plant?.name_ar
      : data?.plant?.name
    : locale === "ar"
    ? sidebarData?.plant?.name_ar
    : sidebarData?.plant?.name;

  const approvedQty = buttons ? quantity : sidebarData?.approved_qty;

  // console.log("imageUrl", imageUrl);
  // console.log("plantName", plantName);
  // console.log("approvedQty", approvedQty);

  return (
    plantName && (
      <div>
        <Row className="card_bg_color">
          <Col sm={2} className="plantRow">
            <div className="plantImage">
              <img
                src={imageUrl} // Fallback to a default image if none is provided
                alt="plant"
                className="imgPlant"
              />
            </div>
          </Col>
          <Col sm={10} className="store">
            <p
              className="plantName"
              style={{ direction: locale === "en" ? "ltr" : "rtl" }}
            >
              {plantName} {/* Fallback to a default name if none is provided */}
            </p>
            {/* <div className="plantButtons" style={{ alignSelf: "end" }}>
              {buttons && (
                <div
                  className="QuantityButton"
                  onClick={() => setQuantity((prev) => Math.max(prev - 1, 0))}
                >
                  <img src={minus} alt="minus" />
                </div>
              )}
              <p className="SelectedNumbers">
                {approvedQty}
              </p>
              {buttons && (
                <div
                  className="QuantityButton"
                  onClick={() => setQuantity((prev) => prev + 1)}
                >
                  <img src={plus} alt="plus" />
                </div>
              )}
            </div> */}
            <div
              className="plantButtons"
              // style={{
              //   alignSelf: buttons == false && "center", // Center align when buttons is false
              //   // display: "flex",
              //   justifyContent: buttons == false && "center", // Center horizontally when buttons is false
              //   // alignItems: "center", // Center vertically
              // }}
              style={{ alignSelf: "end" }}
            >
              <div
                className="QuantityButton"
                onClick={() =>
                  buttons ? setQuantity((prev) => Math.max(prev - 1, 0)) : ""
                }
                style={{
                  cursor: buttons ? "pointer" : "not-allowed",
                }}
              >
                <img src={minus} alt="minus" />
              </div>
              <p className="SelectedNumbers">{approvedQty} </p>
              <div
                className="QuantityButton"
                onClick={() => (buttons ? setQuantity((prev) => prev + 1) : "")}
                style={{
                  cursor: buttons ? "pointer" : "not-allowed",
                }}
              >
                <img src={plus} alt="plus" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  );
};

export default RequestedPlantsCard;

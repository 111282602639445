import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Employee from "./pages/Employee";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./ProtectedRoutes";
import { IntlProvider } from "react-intl";
import { LanguageContext } from "./context/LanguageContext";
import enMessages from "../src/languages/en.json";
import arMessages from "../src/languages/ar.json";
import { useEffect, useState } from "react";
import axios from "axios";

function App() {

  axios.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem("token");

      // Add Authorization header
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      // Set Content-Type based on the type of config.data
      if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
      } else if (typeof config.data === "object" || config.data === undefined) {
        config.headers["Content-Type"] = "application/json";
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const hasToken = sessionStorage.getItem("token");
  const [locale, setLocale] = useState();
  const messages = {
    en: enMessages,
    ar: arMessages,
  };
  useEffect(() => {
    const language = sessionStorage.getItem("lang");
    setLocale(language || "en");
  }, []);

  return (
    <div className="App">
      <IntlProvider locale={locale} messages={messages[locale]}>
        <LanguageContext.Provider value={{ locale, setLocale }}>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/" element={<Employee />} />
              <Route path="/employee" element={<Employee />}></Route>
            </Route>
          </Routes>
        </LanguageContext.Provider>
      </IntlProvider>
    </div>
  );
}

export default App;
